import React from 'react';

import { NumericFormat } from 'react-number-format';

type IPropsInputCurrency = {
  err?: boolean;
  placeholder?: string;
};

export const InputCurrency: React.FC<IPropsInputCurrency> = ({ err, ...rest }) => {
  const renderClass = () => {
    if (err === undefined) return '';
    if (err) {
      return 'error';
    } else {
      return 'success';
    }
  };

  return (
    <NumericFormat {...rest} allowLeadingZeros thousandSeparator="," className={`input-number ${renderClass()}`} />
  );
};

export default InputCurrency;
