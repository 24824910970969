import React from 'react';

import { Button, Modal, ModalProps } from 'antd';

import { CloseIcon } from '@/assets';

type IPropDialog = {
  isOpen: boolean;
  handleClose: () => void;
  children?: string | React.ReactNode;
  handleSubmit?: () => void;
  isCloseButton?: boolean;
} & ModalProps;

export const Dialog: React.FC<IPropDialog> = ({
  isOpen,
  handleClose,
  handleSubmit,
  isCloseButton = false,
  children,
  ...rest
}) => {
  return (
    <Modal
      title=""
      centered
      open={isOpen}
      onOk={handleClose}
      onCancel={handleClose}
      footer={null}
      {...rest}
      closable={false}
      maskClosable={false}
    >
      {isCloseButton && (
        <Button className="titan-close-modal" onClick={handleClose}>
          <CloseIcon />
        </Button>
      )}

      {children}
    </Modal>
  );
};
