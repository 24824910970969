import React from 'react';

import { InfoCircleFilled } from '@ant-design/icons';
import { Tooltip } from 'antd';
import { RequiredIcon } from '@/assets';

interface IMixTitle {
  title?: string | null;
  isTooltip?: boolean;
  isRequired?: boolean;
  tooltipValue?: string | null;
  titleWeight?: number;
}

export const MixTitle: React.FC<IMixTitle> = ({ title, isTooltip, tooltipValue, titleWeight, isRequired }) => {
  return (
    <div className="form-label" style={{ fontWeight: titleWeight ? titleWeight : 600 }}>
      <span>{title}</span>
      {isTooltip && (
        <Tooltip title={tooltipValue ?? ''} className="form-label__tooltip">
          <InfoCircleFilled />
        </Tooltip>
      )}
      {isRequired && (
        <div className="form-label__required">
          <RequiredIcon />
        </div>
      )}
    </div>
  );
};
