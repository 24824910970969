import { SignInImg, TickCircle } from '@/assets';
import React from 'react';
import { useTranslation } from 'react-i18next';
export const LoginLayout: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { t } = useTranslation();
  return (
    <div className="login-layout">
      <div className="signin-page">
        {children}
        <div className="signin-content">
          <div>
            <img src={SignInImg} alt="" />
          </div>
          <div className="signin-content-text">
            <p>
              {t(
                'Top trading bot brings benefits by enhancing the automation of the trading process, optimizing profits, and reducing risks in the financial market.',
              )}
            </p>
            <br />
            <p>{t('Titan trading bot brings benefits by enhancing the automation')}</p>

            <ul className="signin-content-list">
              <li className="signin-content-list-item">
                <TickCircle />
                <span>{t('Increased Feasibility and Efficiency')}</span>
              </li>
              <li className="signin-content-list-item">
                <TickCircle />
                <span>{t('Elimination of Emotional Factors')}</span>
              </li>
              <li className="signin-content-list-item">
                <TickCircle />
                <span>{t('Market Monitoring and Analysis')}</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};
