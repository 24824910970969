import React from 'react';

import { SlashIcon } from '@/assets';
import { Breadcrumb } from 'antd';
import upperFirst from 'lodash/upperFirst';
import { Link, useLocation } from 'react-router-dom';
import { menuSidebar } from '../Layout/constants';
interface IBreadcrumb {}

export const Breadcrumbs: React.FC<IBreadcrumb> = () => {
  const location = useLocation();

  const formatBreadcrumb = (bc: string) => bc.split('-').join(' ');

  const pathCreation = (arr: Array<string>) => {
    return arr.map((item, index) => {
      return {
        name: item,
        path: '/'.concat(arr.slice(0, index + 1).join('/')),
      };
    });
  };
  const pathArray = pathCreation(location.pathname.split('/'));
  const listItem = pathArray?.map((item) => upperFirst(formatBreadcrumb(item.name)));
  if (location.pathname === '/') {
    return (
      <Breadcrumb
        separator={
          <div className="titan-breadcrumb-seperator">
            <SlashIcon />
          </div>
        }
        className="titan-breadcrumb"
      >
        <Breadcrumb.Item key={1} className="titan-breadcrumb__link-current">
          Dashboard
        </Breadcrumb.Item>
      </Breadcrumb>
    );
  }

  const renderLink = (name: string) => {
    const dataSource = [...menuSidebar];
    const linkOld = `/${name.toLowerCase().replace(/ /g, '-')}`;
    let path = '';
    const loop = (data: any, itemName: string, callback: (res: any) => void) => {
      for (let i = 0; i < data.length; i++) {
        if (data[i].path && data[i].path.includes(itemName)) {
          return callback(data[i]);
        }
        if (data[i].items) {
          loop(data[i].items, itemName, callback);
        }
      }
    };
    loop(dataSource, linkOld, (res: any) => {
      if (res.path) {
        path = res.path;
      }
    });
    return path;
  };

  return (
    <Breadcrumb
      separator={
        <div className="titan-breadcrumb-seperator">
          <SlashIcon />
        </div>
      }
    >
      {listItem?.map((item, index) => {
        if (item) {
          return index < pathArray.length - 1 ? (
            <Breadcrumb.Item key={item} className="titan-breadcrumb__link-old">
              <Link to={renderLink(item)}>{item}</Link>
            </Breadcrumb.Item>
          ) : (
            <Breadcrumb.Item key={item} className="titan-breadcrumb__link-current">
              {item}
            </Breadcrumb.Item>
          );
        }
        return '';
      })}
    </Breadcrumb>
  );
};
