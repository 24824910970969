import auth from './src/en/auth.json';
import form from './src/en/form.json';
import placeholder from './src/en/placeholder.json';
import common from './src/en/common.json';
const en = {
  ...auth,
  ...form,
  ...placeholder,
  ...common,
};

export default en;
